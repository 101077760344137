<template>
    <div class="supliers-tab-container">
        <div class="supliers-tab-main-header">
            <FoodServiceTabSelect :tab="tab" :setTab="setTab"/>
            <div class="supliers-tab-filters-container">
                <StandardInput :placeholder="selectedState ? selectedState.est_nome : 'Todos'" :title="'Estado'"
                    class="supliers-tab-filter-size" :value="selectedState" :action="(value) => { selectedState = value; getList() }" :type="'select'"
                    :list="states" />
                <StandardInputListFiltered :placeholder="'Digite a cidade'" :title="'Cidade'" selectWidth="23vw"
                    :action="setCityValues" :value="selectedCity" :list="cities" class="supliers-tab-filter-size" :type="'select'" />
                <Button :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'"
                    :action="() => cleanFilters()" />
            </div>
        </div>
        
        <cc-loader-full v-if="isLoading" />
        <div class="supliers-tab-header">
            <div class="image-width"></div>
            <div class="supliers-tab-header-text name-width">Nome</div>
            <div class="supliers-tab-header-text cnpj-width">Cnpj</div>
            <div class="supliers-tab-header-text city-width">Cidade</div>
            <div class="supliers-tab-header-text state-width">Estado</div>
            <div class="action-width"></div>
        </div>
        <div class="supliers-tab-row" v-for="(store, idx) in list" :key="idx">
            <div class="image-width">
                <img class="supliers-tab-row-img2" v-if="store.link_img_provider" :src="store.link_img_provider" alt="">
                <img v-else src="@/assets/userIcon.svg" class="supliers-tab-row-img">
            </div>
            <div class="supliers-tab-row-text name-width">
                <div class="supliers-tab-monile-header">Nome</div>
                {{ store.for_nome }}
            </div>
            <div class="supliers-tab-row-text cnpj-width">
                <div class="supliers-tab-monile-header">Cnpj</div>
                {{ store.for_cnpj | VMask('## ### ###/####-##') }}
            </div>
            <div class="supliers-tab-row-text city-width">
                <div class="supliers-tab-monile-header">Cidade</div>
                {{ store.cid_nome }}
            </div>
            <div class="supliers-tab-row-text state-width">
                <div class="supliers-tab-monile-header">Estado</div>
                {{ store.est_nome }}
            </div>
            <div class="action-width">
                <div class="supliers-tab-monile-header"></div>
                <div class="supliers-tab-detail-button" v-on:click="setProduct(store)">Mais detalhes</div>
            </div>
        </div>
        <Paginacao classes="orange justify-end" :items_by_page_value="10" :total="listTotal" :page_limit='7' :page="1"
            v-on:change="updatePage($event)">
        </Paginacao>
        <productSupliersDetails v-if="productSelected" :product="productSelected" :close="closeProductDetail" />
    </div>
</template>

<script>
import ProductService from '@/services/v3/management/product.service';
import productSupliersDetails from './product-supliers-details.vue';
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import Button from '@/components/ui/buttons/StandardButton.vue'
import UserService from "@/services/v1/user.service";
import FoodServiceTabSelect from './FoodServiceTabSelect.vue';


export default {
    name: 'ProductSuplierTab',
    props: ['productId','tab', 'setTab'],
    components: { productSupliersDetails, Paginacao, StandardInput, StandardInputListFiltered, Button, FoodServiceTabSelect },
    data() {
        return {
            svc: new ProductService(),
            user_svc: new UserService(),
            list: [],
            productSelected: undefined,
            isLoading: true,
            listTotal: 0,
            selectedState: {},
            selectedCity: {},
            page: 1,
            states: [{ value: {}, text: 'Todos' }],
            cities: []
        }
    },
    created() {
        this.getList()
        this.list_address()
    },
    methods: {
        setProduct(product) { this.productSelected = product },
        closeProductDetail() { this.productSelected = undefined },
        updatePage(event) {
            this.page = event
            this.getList()
        },
        getList() {
            this.isLoading = true
            this.svc.getSupliers(this.productId, this.page, this.selectedCity, this.selectedState).then(resp => {
                this.list = resp.data.data
                setTimeout(() => {
                    this.listTotal = resp.data.total
                }, 500);
                this.isLoading = false
            })
        },
        setCityValues(value) {
            this.selectedCity = value
            this.getList()
        },
        cleanFilters () {
            this.selectedCity = ''; 
            this.selectedState = '';
            this.getList()
        },
        list_address() {
            this.user_svc.region_data().then(response => response.data).then(data => {
                data.estados.forEach(state => {
                    this.states.push({ value: state, text: state.est_sigla })
                })
                this.cities = data.cidades.map(c => ({ value: c, text: c.cid_nome }))
            })
        },
    }
}
</script>

<style>
.supliers-tab-container {
    
}

.supliers-tab-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
}

.supliers-tab-header-text {
    font-size: 1.2em;
    font-weight: 500;
    color: #605F5F;
}

.image-width {
    width: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.supliers-tab-main-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 13vh;
    margin-bottom: 3vh;
}

.supliers-tab-row-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    color: #605F5F;
    font-size: 1.1em;
}
.supliers-tab-filter-size {
    width: 33%;
}

.supliers-tab-row-img2 {
    max-height: 4em;
    max-width: 7em;
}

.supliers-tab-monile-header {
    display: none;
}

.name-width {
    flex: 1;
}

.city-width {
    width: 22%;
}

.state-width {
    width: 13%;
}

.action-width {
    width: 15%;
}

.cnpj-width {
    width: 19%;
}

.supliers-tab-row {
    display: flex;
    align-items: center;
    padding: 1vh 0;
}

.supliers-tab-detail-button {
    border: 1px solid #FF7110;
    border-radius: 8px;
    font-weight: 500;
    color: var(--primary-color);
    text-align: center;
    width: 90%;
    font-size: 1.1em;
    padding: 0.5vh 0;
    cursor: pointer;
}

.supliers-tab-row-img {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
}
.supliers-tab-filters-container {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 47%;
}

@media screen and (max-width: 850px) {
    .supliers-tab-main-header{
        flex-direction: column;
        height: unset;
    }
    .supliers-tab-filters-container{
        width: 100%;
    }
    .supliers-tab-header {
        display: none;
    }
    .supliers-tab-filters-container {
        gap: 0;
        justify-content: space-between;
    }
    .supliers-tab-filter-size {
        width: 32%;
    }

    .supliers-tab-row {
        flex-direction: column;
        gap: 1vh;
        font-size: 1.2em;
    }

    .supliers-tab-row-text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex: 1;
    }

    .image-width {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .action-width {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .supliers-tab-monile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }

    .supliers-tab-detail-button {
        width: 40%;
    }
}
@media screen and (max-width: 550px) {
    .supliers-tab-filters-container {
        flex-direction: column;
        gap: 2vh;
    }

    .supliers-tab-filter-size {
        width: 100%;
    }
}
</style>