<template>
    <div :class="is100Percent ? 'price-tab-container-100' : 'price-tab-container'">
        <div class="price-brand-main-header">
            <FoodServiceTabSelect :tab="tab" :setTab="setTab"/>
            <div class="price-tab-inputs-container">
                <StandardInput :placeholder="selectedState ? selectedState.est_nome : 'Todos'" :title="'Estado'" class="price-tab-inputs-size"
                    :action="(value) => { selectedState = value }" :type="'select'" :list="states" />
                <StandardInputListFiltered :placeholder="'Selecione a cidade'" :title="'Cidade'" class="price-tab-inputs-size"
                    :action="(city) => { selectedCity = city }" :type="'select'" :list="cities" />
                <Button :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'"
                    :action="() => { selectedCity = undefined; selectedState = undefined }" />
            </div>
        </div>
        
        <cc-loader-full v-if="isLoading" />
        
        <div class="price-tab-chart-container">
            <div class="price-tab-filter-container">
                <div class="price-tab-info price-tab-info-min"> <b>Preço Mínimo Geral:</b> R$ {{ min.toFixed(2) }}</div>
                <div class="price-tab-info price-tab-info-med"> <b>Preço Médio Geral:</b> R$ {{ media.toFixed(2) }}</div>
                <div class="price-tab-info price-tab-info-max"> <b>Preço Máximo Geral:</b> R$ {{ max.toFixed(2) }}</div>
            </div>
            <div v-show="showNoChart" class="price-tab-no-chart">
                Desculpe, informações não disponiveis para esse produto.
            </div>
            <BarChartComponent v-if="!showNoChart && barChartInfo" :clickEvent="(colum) => ColumDetail = colum" :info="barChartInfo"/>
        </div>

    <ColumDetails v-if="ColumDetail" :name="name" :id="productId" :ColumDetail="ColumDetail" :close="() => ColumDetail = undefined"/>
    </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import Button from '@/components/ui/buttons/StandardButton.vue'
import Chart from 'chart.js'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import ProductService from '@/services/v3/management/product.service';
import FoodServiceTabSelect from './FoodServiceTabSelect.vue';
import BarChartComponent from './BarChartComponent.vue';
import ColumDetails from './ColumDetails.vue';

export default {
    name: 'PriceBrandTab',
    components: {
        StandardInput,
        Button,
        StandardInputListFiltered,
        FoodServiceTabSelect,
        BarChartComponent,
        ColumDetails
    },
    props: ['productId', 'is100Percent', 'states', 'cities','tab', 'setTab', 'name'],
    data() {
        return {
            selectedState: undefined,
            selectedCity: undefined,
            myChart: undefined,
            showNoChart: false,
            isLoading: true,
            ColumDetail: undefined,
            barChartInfo: undefined,
            min: 0,
            media: 0,
            max: 0,
            svc: new ProductService(),
            priceInfo: {
                type: "line",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "Média preço máximo",
                            data: [],
                            fill: false,
                            borderColor: "#E63535",
                            tension: 0.2,
                            borderWidth: 5
                        },
                        {
                            label: "Preço médio",
                            data: [],
                            fill: false,
                            borderColor: "#FF7110",
                            tension: 0.2,
                            borderWidth: 5
                        },
                        {
                            label: "Média preço mínimo",
                            data: [],
                            fill: false,
                            borderColor: "#30AA4C",
                            tension: 0.2,
                            borderWidth: 5
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 1,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    padding: 25
                                }
                            }
                        ]
                    },
                    plugins: {
                        legend: {
                            display: false,
                            title: {
                                display: false
                            }
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    hover: {
                        mode: 'index',
                        intersect: false
                    }
                }
            }
        }
    },
    watch: {
        selectedState() {
            this.load()
        },
        selectedCity() {
            this.load()
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.isLoading = true
            const payload = { pro_padrao_id: this.productId, city: this.selectedCity?.cid_nome, fu: this.selectedState?.est_sigla }
            this.svc.getPriceBrand(payload).then(resp => {
                this.priceInfo.data.labels = []
                this.priceInfo.data.datasets[0].data = []
                this.priceInfo.data.datasets[1].data = []
                this.priceInfo.data.datasets[2].data = []
                this.isLoading = false
                if (resp.data.length < 1) {
                    this.showNoChart = true
                    return
                }
                this.barChartInfo = resp.data
                this.showNoChart = false
                let min = 0
                let media = 0
                let max = 0
                resp.data.forEach(element => {
                    this.priceInfo.data.labels.push(this.getMonthText(element.mes))
                    this.priceInfo.data.datasets[2].data.push(element.min_valor.toFixed(2) )
                    min += element.min_valor
                    this.priceInfo.data.datasets[1].data.push(element.media.toFixed(2))
                    media += element.media
                    this.priceInfo.data.datasets[0].data.push(element.max_valor.toFixed(2))
                    max += element.max_valor
                });
                this.min = min / resp.data.length
                this.media = media / resp.data.length
                this.max = max / resp.data.length
                const ctx = document.getElementById('planet-chart');
                Chart.defaults.global.legend.display = false;
                if (!this.myChart && resp.data.length > 0) this.myChart = new Chart(ctx, this.priceInfo);
                else {
                    this.myChart.data.labels = this.priceInfo.data.labels
                    this.myChart.data.datasets[2] = this.priceInfo.data.datasets[2]
                    this.myChart.data.datasets[1] = this.priceInfo.data.datasets[1]
                    this.myChart.data.datasets[0] = this.priceInfo.data.datasets[0]
                    this.myChart.update()
                }

            }).catch(() => {
                this.isLoading = false
            })
        },
        getMonthText(value) {
            const year = value.split(' ')[1]
            let month = value.split(' ')[0]
            if (month == 'January') month = 'Janeiro'
            else if (month == 'February') month = 'Fevereiro'
            else if (month == 'March') month = 'Março'
            else if (month == 'April') month = 'Abril'
            else if (month == 'May') month = 'Maio'
            else if (month == 'June') month = 'Junho'
            else if (month == 'July') month = 'Julho'
            else if (month == 'August') month = 'Agosto'
            else if (month == 'September') month = 'Setembro'
            else if (month == 'October') month = 'Outubro'
            else if (month == 'November') month = 'Novembro'
            else if (month == 'December') month = 'Dezembro'
            return month + ' - ' + year
        }
    }
}
</script>

<style>

.price-tab-container-100 {
    padding: 1em 2em;
    height: 70vh;
    overflow: auto;
}

.price-tab-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3vh;
}
.price-tab-inputs-size{
    width: 33%;
}

.price-tab-inputs-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -1.5vh;
    width: 47%;
}

.price-tab-no-chart {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: lightgray;
    font-weight: bold;
}

.price-brand-main-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 13vh;
    margin-bottom: 3vh;
}

.price-tab-filter-input {
    width: 35%;
}

.price-tab-info {
    padding: 1vh 0;
    text-align: center;
    font-size: 1.2em;
    border-radius: 8px;
    width: 30%;
}

#planet-chart[id='legend'] {
    display: none;
}

.price-tab-info-min {
    background: rgba(48, 170, 76, 0.15);
    color: #30AA4C;
}

.price-tab-info-med {
    background: rgba(255, 113, 16, 0.15);
    color: var(--primary-color);
}

.price-tab-info-max {
    background: rgba(230, 53, 53, 0.1);
    color: #E63535;
}

.price-chart-container {
    width: 80%;
    margin: 0 auto;
}

@media screen and (max-width: 850px) {
    .price-brand-main-header{
        flex-direction: column;
        height: unset;
    }
    .price-tab-container {
        flex-direction: column;
    }

    .price-tab-inputs-container {
        width: 100%;
        padding-top: 2vh;
        gap: 2vh;
        flex-direction: column;
        align-items: stretch;
    }
    .price-tab-inputs-size{
        width: 100%;
    }
    .price-tab-filter-container{
        flex-direction: column;
        gap: 1vh;
    }
    .price-tab-info{
        width: 100%;
    }
    .price-chart-container{
        width: 100%;
    }
    .price-tab-chart-container {
        width: 100%;
    }

    .price-tab-filter-container {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}</style>