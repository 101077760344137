<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    {{ product.for_nome }}
                </span>
                <span @click="close()" class="material-icons new-product__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="product-details-content">
                <div class="product-datails-row">
                    <div class="product-datails-field">
                        Nome
                        <div class="product-details-field-value" :title="product.for_nomeFantasia">{{ product.for_nomeFantasia || '--'  }}</div>
                    </div>
                    <div class="product-datails-field">
                        Email
                        <div class="product-details-field-value" :title="product.for_email">{{ product.for_email || '--'  }}</div>
                    </div>
                </div>
                <div class="product-datails-row">
                    <div class="product-datails-field">
                        Contato
                        <div class="product-details-field-value" :title="product.for_contato">{{ product.for_contato || '--'  }}</div>
                    </div>
                    <div class="product-datails-field">
                        Telefone
                        <div class="product-details-field-value"> {{ product.for_telefone || '--' }}</div>
                    </div>
                </div>
                <div class="product-datails-row">
                    <div class="product-datails-field">
                        Site
                        <div class="product-details-field-value" :title="product.for_site">{{ product.for_site || '--'  }}</div>
                    </div>
                    <div class="product-datails-field">
                        Cadastro
                        <div class="product-details-field-value">{{ product.for_dataCadastro || '--'  }}</div>
                    </div>
                </div>
                <div class="product-datails-row">
                    <div class="product-datails-field">
                        Cidade
                        <div class="product-details-field-value">{{ product.cid_nome || '--'  }}</div>
                    </div>
                    <div class="product-datails-field">
                        Estado
                        <div class="product-details-field-value">{{ product.est_nome || '--'  }}</div>
                    </div>
                </div>
                <div class="product-datails-row">
                    <div class="product-datails-field">
                        CNPJ
                        <div class="product-details-field-value">{{ product.for_cnpj || '--'  }}</div>
                    </div>
                    <div class="product-datails-field">
                        Cadastro
                        <div class="product-details-field-value">{{ product.for_dataCadastro || '--'  }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProductSupliersDetails',
    props: ['product', 'close']
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    width: 70vw;
    height: 80vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2em;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.product-details-content {
    margin-top: 7vh;
    padding: 0 3vw;
    height: 55vh;
    overflow: auto;
}

.product-datails-row {
    display: flex;
    justify-content: space-between;
}

.product-datails-field {
    font-size: 1.3em;
    margin-bottom: 2vh;
}

.product-details-field-value {
    border: 1px solid #e5e5e5;
    width: 30vw;
    padding: 0.5vh 1vw;
    color: #605F5F;
    border-radius: 8px;
    margin-top: 0.5vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 800px) {
    .modal-mask{font-size: 2vw;}
    .product-datails-row{
        flex-direction: column;
    }
    .product-details-field-value{
        width: 100%;
    }
  }
  @media screen and (max-width: 450px){
    .modal-mask{font-size: 3vw;}
    .modal-content-container{width: 90vw;}
  }

</style>